<template>
    <!-- eslint-disable -->
    <Form :action="action" :labelVal="label" :jumlahHariVal="jumlahHari" :layananVal="layanan" :id="id" :kodeVal="kode" :dataLayananVal="dataLayanan" />
</template>

<script>
import Form from './Form.vue'
import {ref} from '@vue/composition-api'

export default {
    setup(props, context){
        const g = context.root

        const $http = g.$http

        var label = ref('')
        var jumlahHari = ref('')
        var kode = ref('')
        var layanan = ref('')
        var dataLayanan = ref([])

        const getData = () => {
            $http({
                url: '/pemeriksaan/jenisPemeriksaan/ubah/'+props.id,
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {labelVal, jumlahHariVal, kodeVal, layananVal, dataJenisPemeriksaan} = res.data.data
                    label.value = labelVal
                    jumlahHari.value = jumlahHariVal.toString()
                    kode.value = kodeVal
                    layanan.value = layananVal
                    dataLayanan.value = dataJenisPemeriksaan.dataLayananVal
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()

        return {
            label, jumlahHari, kode, layanan, dataLayanan
        }
    },
    data(){
        return{
            action: 'Ubah'
        }
    },
    components: {
        Form
    },
    props: ['id']
}
</script>